import React, { FC } from 'react';
import WhereToBuyCard from './WhereToBuyCard';

import { WhereToBuyListProps } from './models';
import './WhereToBuyList.scss';

const WhereToBuyList: FC<WhereToBuyListProps> = ({ whereToBuyTitle, whereToBuyCardsList }) => (
  <div className="where-to-buy-list" data-testid="where-to-buy-list">
    <h1 className="where-to-buy-list__title">{whereToBuyTitle}</h1>
    <div className="where-to-buy-list__cards">
      {whereToBuyCardsList.map((whereToBuyCardProps: PageContent.WhereToBuyCardType) => (
        <WhereToBuyCard key={whereToBuyCardProps.buttonUrl} {...whereToBuyCardProps} />
      ))}
    </div>
  </div>
);

export default WhereToBuyList;
