// THIS SITE WAS CREATED FOR VEET PK
import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'layout';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import WhereToBuyList from 'components/WhereToBuyList';

import createBreadcrumbs from 'utils/createBreadcrumbs';

import './WhereToBuyPage.scss';

const WhereToBuyPage: FC<WhereToBuyTypes.WhereToBuyProps> = ({
  data: {
    allUmbracoWhereToBuy: { nodes: whereToBuyNodes },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
  },
  location: { pathname },
}) => {
  const {
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage },
    whereToBuyTitle,
    whereToBuyCardsList,
  } = whereToBuyNodes[0];

  const breadcrumbs = {
    breadCrumb: createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname),
  };

  return (
    <Layout breadcrumbs={breadcrumbs} langProps={{ urls, lang }}>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          ogImage,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <WhereToBuyList {...{ whereToBuyTitle, whereToBuyCardsList }} />
    </Layout>
  );
};

export const query = graphql`
  query WhereToBuyQuery($pageId: String) {
    allUmbracoWhereToBuy(filter: { id: { eq: $pageId } }) {
      nodes {
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
          ogImage
        }
        whereToBuyTitle
        whereToBuyCardsList {
          image {
            ...umbracoImage
          }
          imageAlt
          title
          buttonText
          buttonUrl
          buttonAriaLabel
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default WhereToBuyPage;
