import React, { FC } from 'react';
import { navigate } from 'gatsby';
import NiceCard from 'components/NiceCard';
import { WhereToBuyCardProps } from './models';
import ButtonPosition from '../../NiceCard/CardButtonPositionEnum';

const WhereToBuyCard: FC<WhereToBuyCardProps> = ({
  image,
  imageAlt,
  title,
  buttonText,
  buttonUrl,
  buttonAriaLabel,
}) => {
  const topCardContent = {
    cardImage: {
      image,
      imageAlt,
    },
  };
  const bottomCardContent = {
    title,
    buttons: [
      {
        text: buttonText,
        onClick: () => navigate(buttonUrl),
        ariaLabel: buttonAriaLabel,
        position: ButtonPosition.Center,
        isReversed: { variant: true },
      },
    ],
  };

  return <NiceCard {...{ topCardContent, bottomCardContent }} />;
};

export default WhereToBuyCard;
